import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.less';
import './Navigation.less';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay]);
import videoControl from '../../../../../components/dialog-video-neo';
import './index.less';

/**
 * 可选参数
 */
interface IProps {
	className?: string;
}

/**
 * 荣誉奖项
 *
 * @class HomeTrainingTopBanner
 * @extends {React.Component<any>}
 */
class HomeTrainingTopBanner extends Component<IProps> {

	public state: any = {
		banners: [
			// {
			// 	backgroundUrl: 'https://img.qlchat.com/qlLive/activity/image/MNZ1KNK6-LNPM-KLUL-1717568795412-LC1HWWDB3WXP.jpg?x-oss-process=image/format,webp',
			// 	linkUrl:''
			// },
			{
				backgroundUrl:'https://img.qlchat.com/qlLive/activity/image/GWPZ7HUY-U286-5JFA-1715323794151-EL5AOK97JM3E.jpg?x-oss-process=image/format,webp',
			}
			// {
			// 	backgroundUrl: 'https://img.qlchat.com/qlLive/activity/image/VQEL6A4M-SC4B-SKW3-1710212890930-564N17MYDIUB.png?x-oss-process=image/format,webp',
			// },
			// {
			// 	backgroundUrl: 'https://img.qlchat.com/qlLive/activity/image/8HFKBE93-E7AZ-22ZS-1710212893854-K4ARFYLBB789.png?x-oss-process=image/format,webp',
			// },
		]
	};

	playBrandVideo(){
		const params = {
			"cover": "",
			"resourceURL": "https://media.qlchat.com/qlLive/activity/video/U1R6AVKX-96CO-GBSM-1715337589389-K6NO63YD4P2V.mp4"
		}
		videoControl.playVideoDialog(params);
	}

	/**
	 * 渲染页面
	 *
	 * @returns {*}
	 */
	public render(): any {
		const { banners } = this.state;
		const bannersFlag: boolean = banners.length > 1;

		return (
			<div className="home-training-top-banner">
					<div className='brand-video-container'>
						<div className='brand-video' onClick={this.playBrandVideo}>
								<div className='brand-name'>兴趣岛</div>
								<div className='brand-desc'>国内领先的兴趣学习品牌</div>
								<div className='brand-video-box'>
									<img className='course-img' src="https://img.qlchat.com/qlLive/activity/image/S64K9JCC-PP8A-XZW2-1715572051123-7JAIKCENIKZ2.png?x-oss-process=image/format,webp" />
									<div className='brand-video-btn'>
										<span>
											1分钟了解兴趣岛
										</span>
										<img className='brand-video-btn-icon' src="https://img.qlchat.com/qlLive/activity/image/NL3ZFZ4T-HDXV-VBEY-1708569016463-WFGM9POAOYH5.png" />
									</div>
								</div>
						</div>
					</div>
					<Swiper
						// navigation={bannersFlag}
						loop={bannersFlag}
						autoplay={{
							delay: 5000,
							disableOnInteraction: false,
						}}
						// pagination={{ clickable: true }}
						className="home-training-top-banne-swiper">
						{banners.map((item: any, index: number): any => (
							<SwiperSlide key={index}>
								<div
									className="home-training-top-banne-swiper-img"
									onClick={(): void => {
										if (item.linkUrl) {
											window.open(item.linkUrl);
										}
									}}
									style={{
										background: `url(${item.backgroundUrl}) no-repeat center 0`,
										backgroundSize: 'auto 100%',
									}}></div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
		);
	}
}

export default HomeTrainingTopBanner;
