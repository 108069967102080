import { FC, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper/core';
SwiperCore.use([Autoplay]);
import 'swiper/swiper.less';
import './style.less';

interface IProps {
  classNames?: string
}
/** 品牌报道 */
const BrandReport: FC<IProps> = ({classNames}) => {
  const swipeRef = useRef(null);
  const list = [
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/7X3927EM-8Y9O-5IWI-1709002649357-KBY7JMT7YV42.png?x-oss-process=image/format,webp',
      title:'兴趣岛兴趣学堂受邀出席2023年度中国营养学会交流会议',
      content:'2023年12月，作为中国营养学会（CNS）单位会员，兴趣岛受邀出席“2023年度中国营养学会单位会员交流会议”，与多方学会理事、专家学者、单位会员代表齐聚现场，就“第十四届亚洲营养大会暨第十六届全国营养科学大会”会议成果及中国营养学会过去一年的工作作出总结，畅议2024新计...',
      link:'https://www.3news.cn/pindao/2024/0103/012024_1060606.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/CZPAOS44-3BYB-74AJ-1709002652926-5WP3A2OHX94Q.png?x-oss-process=image/format,webp',
      title:'兴趣岛荣获腾讯教育“回响中国”2023年度影响力教育品牌',
      content:'2023年11月，以“科技赋能，创新未来”为主题，2023“回响中国”腾讯新闻 · 教育频道年度高峰论坛颁奖盛大召开，兴趣岛兴趣学堂受邀出席大会现场。 现场云集国际教育专家、教育研究机构代表、国际学校创始人、校长等数百位嘉宾，共谈教育理念与经验，共商未来发展与方...',
      link:'https://qiye.chinadaily.com.cn/a/202312/18/WS657fb390a310c2083e4134fd.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/Q63NWZUR-966J-OBYX-1709002656366-QMTFNWE295TM.png?x-oss-process=image/format,webp',
      title:'兴趣岛致力全民身心健康发展，助力广州城市管理普法宣传行动',
      content:'随着2023的列车载着对兴趣的热爱逐渐驶向站台兴趣岛迎来又一全民行动： 2023年12月2日兴趣岛作为爱心企业联合广州市城市管理志愿者协会参与由共青团广州市城市管理和综合执法局委员会主办的“城管普法暖人心 垃圾分类绿色行”宣传活动看，共同促进广州市城市管理志愿服务工...',
      link:'https://www.xhby.net/content/s6572eb28e4b06569fa93b7fb.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/Z77QINQ6-9X16-M273-1709002662397-BEQRMZ5FIPF4.png?x-oss-process=image/format,webp',
      title:'首届健康广州减脂大赛圆满成功，兴趣岛共助全民营养健康',
      content:'12月2日，由兴趣岛兴趣学堂鼎力支持的2023健康广州减脂大赛闭幕式暨颁奖仪式在花都芙蓉会议中心举行。本次大赛由广州市体育科学研究所主办，广州市凯越体育发展有限公司承办，兴趣岛联合广州市群众体育指导中心和广州市体育彩票管理中心等多家单位对赛事给予大力支持。',
      link:'https://k.sina.com.cn/article_7867989815_1d4f7ff3700101bdkp.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/Q63NWZUR-966J-OBYX-1709002656366-QMTFNWE295TM.png?x-oss-process=image/format,webp',
      title:'兴趣岛开放日：以中医养生为题，铸学员共创之梦',
      content:'中医文化博大精深，我学习中医养生是为了给家人保驾护航，学会之后面对小孩的生病的时候也可以清楚、协助调理，不会那么迷茫和害怕了。”——AXY·娜 作为一个线上兴趣教育品牌，我们来自五湖四海的学员与学友、老师大多都是在线上交流学习，虽然网络让我们跨越时间距离相...',
      link:'https://www.xhby.net/content/s6572ea78e4b05189f88f523a.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/CZPAOS44-3BYB-74AJ-1709002652926-5WP3A2OHX94Q.png?x-oss-process=image/format,webp',
      title:'广州市天河区科技工业和信息化局各领导莅临兴趣岛总部参观指导...',
      content:'心系终身教育，助力广大国人精彩兴趣生活。2023年11月28日，广州市天河区科技工业和信息化局局长宋爱平女士等领导莅临兴趣岛总部参观指导。此行不仅展示了上级单位对于地区企业发展的支持和助力，也表达了对于兴趣岛在兴趣教育领域成长和发展的认可。',
      link:'https://cn.chinadaily.com.cn/a/202312/04/WS656d68eca310d5acd877173a.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/VL3F673N-A4GS-GSEP-1709002785014-LJS2UEA9LOGN.png?x-oss-process=image/format,webp',
      title:'兴趣岛荣获CGMA全球管理会计2023年度“最佳管理会计实践”...',
      content:'11月23日，兴趣岛受邀出席AICPA & CIMA国际注册专业会计师公会在上海主办的“财界奥斯卡” - CGMA全球管理会计2023年度中国大奖暨CFO高峰论坛（以下简称“2023 CGMA年度大奖”），在此次论坛上，兴趣岛荣获了CGMA全球管理会计2023年度“最佳管理会计实践”领域中国...',
      link:'https://biz.ifeng.com/c/8VEoqiw4KO3',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/VL3F673N-A4GS-GSEP-1709002785014-LJS2UEA9LOGN.png?x-oss-process=image/format,webp',
      title:'兴趣岛开放日：体验共创，以中医文化链接名师益友',
      content:'一直以来，因时间和地域问题，学员与老师、学友间的交流分享也只能通过文字和语音表达。网络让我们跨越各种距离相聚学习，但许多学员仍希望拥有一次面对面交流学习、触及最真实温暖的一次体验。 “用户第一”是兴趣岛的核心价值观，为满足学员期待，与用户构筑更好的兴趣学堂...',
      link:'https://hebei.ifeng.com/c/8UhN8Lvfy66',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/18M178VA-CDIH-AZ1R-1709002780985-EKU9C71JZUFS.png?x-oss-process=image/format,webp',
      title:'广州高科技高成长20强及明日之星评选代表团莅临兴趣岛总部',
      content:'2023年10月，兴趣岛参评并入围广州高科技高成长20强及明日之星评选项目，德勤中国与大湾区科技创新服务中心评选活动代表团莅临兴趣岛总部指导，与兴趣岛深入探讨创新技术、科学发展赋能企业成长，助力企业高质量发展。 广州高科技高成长20强及明日之星评选项...',
      link:'https://tech.china.com/article/20231113/112023_1438218.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/Q63NWZUR-966J-OBYX-1709002656366-QMTFNWE295TM.png?x-oss-process=image/format,webp',
      title:'助力全民健康丨兴趣岛成为“中国营养学会”会员单位',
      content:'2023年9月，兴趣岛正式成为中国营养学会（CNS）会员单位，继2021年被广州市科学技术局评为 “广州市未来独角兽创新企业”，2022年被评为“广东省专精特新企业”之后，再次为权威机构所认可，通过严苛考验标准，成为了更有影响力与说服力的名师兴趣课堂平台。',
      link:'https://www.xhby.net/content/s6552da59e4b032a41bc06512.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/18M178VA-CDIH-AZ1R-1709002780985-EKU9C71JZUFS.png?x-oss-process=image/format,webp',
      title:'兴趣岛太极同修问道武当游研学丨世界太极大会，全民文化传承',
      content:'“国潮”“非遗”“太极”，这些优秀的中华传统文化，从中华大地的沃土里生根发芽，又化成星星点点散落到中华大地。武当山顶是道家太极起源地，为让更多人真正走进太极，登顶武当感受中华文脉的魅力。 2023年10月21至10月24日，在中华非遗胜地--武当山上，兴趣岛联合武当大学堂...',
      link:'https://henan.china.com/news/roll/2023/1030/102023_615496.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/MT3J6YGG-5UMJ-RDQD-1711503992327-Q1T76E1RHU3Q.png?x-oss-process=image/format,webp',
      title:'世界太极齐聚武当山丨兴趣岛·趣传承全民太极节正式启动',
      content:'一、非遗，要代代“传”起来 中华优秀传统文化是中华民族的根和魂，是中华民族永续发展的精神命脉，而非物质文化遗产，则是各族人民世代相传的宝贵财富。 传承非遗，需要我们用行动践行国之大者，脚踏实地，利国利民，为新时代弘扬武当...',
      link:'https://www.163.com/dy/article/IIAA5M420552XKUM.html',
    },
    {
      channelImg:'https://img.qlchat.com/qlLive/activity/image/EIAY848U-7ZO3-VWHU-1715420664347-5D6TM1XE6D35.png?x-oss-process=image/format,webp',
      title:'官宣！赵雅芝代言兴趣岛品牌，公开保持健康年轻态秘诀',
      content:'5月10日，国内领先的兴趣教育品牌兴趣岛官宣首位品牌代言人，赵雅芝成为“兴趣岛品牌体验官”。赵雅芝因多部经典影视作品和善于保养为大众所熟知。提及双方合作契机，她坦言，有个兴趣爱好，是件很幸福的事...',
      link:'http://news.china.com.cn/mts/2024-05/11/content_1192453.htm',
    },
  ];

  const onSlidePrev = ()=>{
    swipeRef.current?.slidePrev?.();
  }

  const onSlideNext = ()=>{
    swipeRef.current?.slideNext?.();
  }
  
  const changeAutoPlay = () => {
    if(!swipeRef.current.autoplay.running){
      swipeRef.current.autoplay?.start?.();
    }else{
      swipeRef.current.autoplay?.stop?.();
    }
  }

  const getSwipeInstance = (swiper)=>{
    swipeRef.current = swiper;
  }

	return (
		<div className={`home-brand-report-container ${classNames}`}>
      <div className="brand-report-list">
        <div className='brand-report-prev flex-center' onClick={onSlidePrev}><i className="iconfont iconxiaojiantou to-left"></i></div>
        <div className='brand-report-next flex-center' onClick={onSlideNext}><i className="iconfont iconxiaojiantou"></i></div>
        <Swiper
          onSwiper={getSwipeInstance}
          // navigation={true}
          slidesPerView={'auto'}
          loop={true}
          observer
          observeParents
          observeSlideChildren
          centeredSlides
          spaceBetween={48}
          // autoplay={{
          //   delay: 3000,
          //   disableOnInteraction: false,
          // }}
          className="brand-report-swiper">
          {list.map((item: any, index: number): any => (
            <SwiperSlide key={index} className="brand-report-slide">
              <div
                className="brand-report-item"
                key={index}
                onMouseOver={changeAutoPlay}
                onMouseOut={changeAutoPlay}
                onClick={() => window.open(item?.link)}
                >
                <div className='report-item-wrap'>
                  <img className='channel-img' src={item.channelImg} />
                  <div className='title'>{item.title}</div>
                  <div className='content'>
                    {item.content}
                  </div>
                </div>
                <div className='view-detail-text flex-center'>
                  查看详情
                  <i className="iconfont iconxiaojiantou"></i>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
		</div>
	);
};
export default BrandReport;
